import Toyota from '../Images/Car1.png';
import Honda from '../Images/Car2.png';
import Nisan from '../Images/Car3.png';
import Suzuki  from '../Images/Car4.png';
import Rangerover from '../Images/Car6.png';
import Toyota1 from '../Images/Car1.png';
import Honda1 from '../Images/Car2.png';
import Nisan1 from '../Images/Car3.png';
import Suzuki1  from '../Images/Car4.png';
import Rangerover1 from '../Images/Car6.png';
import Toyota2 from '../Images/Car1.png';
import Honda2 from '../Images/Car2.png';
import Nisan2 from '../Images/Car3.png';
import Suzuki2  from '../Images/Car4.png';
import Rangerover2 from '../Images/Car6.png';
import Toyota3 from '../Images/Car1.png';
import Honda3 from '../Images/Car2.png';
import Nisan3 from '../Images/Car3.png';
import Suzuki3  from '../Images/Car4.png';
import Rangerover3 from '../Images/Car6.png';

// const carImages ={
//     Toyota,
//     Honda,
//     Nisan,
//     Suzuki ,
//     Rangerover,

// };
const carImages = [
    {
        id: "001",
        image1:Toyota,
        image2:Toyota1 ,
        image3:Toyota2,
        image4:Toyota3

    },
    {
        id:"002",
        image1:Honda,
        image2: Honda1,
        image3:Honda2,
        image4:Honda3

    },
    {
        id:"003",
        image1:Nisan,
        image2: Nisan1,
        image3:Nisan2,
        image4:Nisan3

    },
    {
        id:"004",
        image1:Suzuki,
        image2: Suzuki1,
        image3:Suzuki2,
        image4:Suzuki3

    },
    {
        id:"005",
        image1:Rangerover,
        image2: Rangerover1,
        image3:Rangerover2,
        image4:Rangerover3

    },

    {
        id:"006",
        image1:Toyota,
        image2:Toyota1 ,
        image3:Toyota2,
        image4:Toyota3

    }
]

export default carImages;
